import React, { useEffect } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

export default function TermsOfService() {
  useEffect(() => {
    document.title = 'The Ghost | Terms of Service';
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='terms-of-service'>
      <h1>The Ghost Terms of Service</h1>

      {/* spacing */}
      <div style={{ height: '25px' }} />

      <h2>Effective Date: February 26, 2025</h2>

      <div // spacing
        style={{ height: '25px' }}
      />

      {/* <p>These terms and conditions apply to the The Ghost videogame (hereby referred to as "Game") that was created by Gameplier (hereby referred to as "Developer")</p> */}
      <p>The Ghost (“<b>Game</b>”) is a game software developed by Gameplier (“<b>Developer”</b>). The Developer provides game services, and other game related services (Collectively, “<b>Service”</b>). “<b>User”</b> means the person who agrees to and consents to these Terms of Use (the “<b>Terms”</b>). By using the Services, you accept and agree to be bound and abide by these Terms of Service. If you do not want to agree to these terms and conditions, you must not use the Service.</p>
      {/* <p>Upon downloading or utilizing the Game, you are automatically agreeing to the following terms. It is strongly advised that you thoroughly read and understand these terms prior to using the Game.</p> */}

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>1. Updating Services and Terms</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p style={{ marginBottom: '5px' }}>(1) Terms of Service can be found at any time from the Developer's website.</p>
      <p style={{ marginBottom: '5px' }}>(2) The Developer may change these Terms and Conditions at any time if necessary to the extent that is consistent with applicable laws and regulations.</p>
      <p style={{ marginBottom: '5px' }}>(3) You are responsible to check this page regularly to make note of any changes. Your continued use of the Services means that you accept and agree to the changes of Terms of Service.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>2. Prohibited Uses</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p>The Developer provides Service to users only for personal, non-commercial and entertainment purposes. You agree not to violate terms and conditions set in this Term of Service. Violation of any criteria in this agreement may lead to temporary or permanent suspension of the service in accordance with the terms and conditions. You agree to only use the Service, or any part of it, in a manner that is consistent with this License and you SHALL NOT:</p>

      <div // spacing
        style={{ height: '15px' }}
      />

      <p style={{ marginBottom: '5px' }}>(1) Use the Game, or permit use of the Game, in a network, multi-user arrangement or remote access arrangement, including any online use, except as otherwise explicitly permitted by the Game in writing;</p>
      <p style={{ marginBottom: '5px' }}>(2) Make copies of the Game or any part thereof, except that you may make one (1) copy of the Game for backup or archival purposes, or make copies of the materials accompanying the Game for non-commercial backup and reference only;</p>
      <p style={{ marginBottom: '5px' }}>(3) Sell, rent, lease, license, distribute, upload to any Internet server or web site, or otherwise transfer any portion of this Game or any copies without the express prior written consent of the Game.</p>
      <p style={{ marginBottom: '5px' }}>(4) Remove, disable or circumvent any security protections or any technical measures that control access to the Game;</p>
      <p style={{ marginBottom: '5px' }}>(5) Remove, modify, deface or circumvent any proprietary notices or labels contained on or within the Game;</p>
      <p style={{ marginBottom: '5px' }}>(6) Create data or executable programs that mimic data or functionality in the Game;</p>
      <p style={{ marginBottom: '5px' }}>(7) Steal and/or publish information from others (including but not limited to personal information, non-personal information and payment information);</p>
      <p style={{ marginBottom: '5px' }}>(8) Impersonate Developer;</p>
      <p style={{ marginBottom: '5px' }}>(9) Harm reputation of the Developer, our affiliates, our service providers and licensors as well as the Game;</p>
      <p style={{ marginBottom: '5px' }}>(10) Post, link to, advertise, promote, or transmit anything that is illegal, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, sexually explicit, obscene, hateful, or racially or ethnically offensive;</p>
      <p style={{ marginBottom: '5px' }}>(11) Take advantage by exploiting others in any way;</p>
      <p style={{ marginBottom: '5px' }}>(12) Use the Service as a manner that is related to gambling or any action that negatively impacts the delivery of the Service;</p>
      <p style={{ marginBottom: '5px' }}>(13) Exploit errors and bugs found while playing the game;</p>
      <p style={{ marginBottom: '5px' }}>(14) Access or use the Services for any purpose that is illegal or beyond the scope of the Services' intended use (in Company's sole judgment);</p>
      <p style={{ marginBottom: '15px' }}>(15) Use / develop / advertise / trade / distribute;</p>
      <p>* Programs that intercept communication between a game and a game server;</p>
      <p style={{ marginBottom: '15px' }}>* Any unauthorized third party programs or hardware devices that may affect the game or normal game play, including but not limited to, hacks, cheats, helpers, bots, scripts, trainers, automatic programs, programs that collect or modify game data by reading the game memory.</p>
      <p style={{ marginBottom: '5px' }}>(16) Disrespect or use offensive words towards others based on their race, gender, nationality;</p>
      <p style={{ marginBottom: '5px' }}>(17) create offensive nicknames or those that may provoke negative imagery;</p>
      <p style={{ marginBottom: '5px' }}>(18) stalk other players from match to match;</p>
      <p>(19) manipulate the results of the game in an unfair way;</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p>Any action which is not defined in this Terms of Service that negatively impacts the delivery of the Game's services or negatively affects other players may be subject to warning and penalty through careful review of the circumstances.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p>Furthermore, you agree that you shall abide by the safety information, maintenance instructions or other relevant notices contained in the manual that is included with the Game.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>3. Accessing the Service</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p style={{ marginBottom: '5px' }}>(1) As a general rule, the service of the game is accessible for 24 hours a day throughout the whole year.</p>
      <p style={{ marginBottom: '5px' }}>(2) Notwithstanding the provision of paragraph 1, the Developer will not be liable For any delay or failure to perform resulting from any cause outside the reasonable control of the Developer, including, without limitation, war, terrorism, riots, embargoes, acts of civil or military authorities, change of law or administrative rules, fire, floods, network infrastructure failures, cyber crimes, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.</p>
      <p style={{ marginBottom: '5px' }}>(3) We may amend the Service or restrict access (including cancellation, termination, modification or suspension of a user;s license) from all or specific users without notice and liability. Additionally, the Service may be unavailable depending on geographic location.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>4. Ownership</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p style={{ marginBottom: '5px' }}>(1) You agree and acknowledge that all title, ownership rights, and intellectual property rights connected with the Game and any and all copies thereof (including but not limited to any derivative works, titles, computer code, themes, objects, characters, character names, stories, dialogs, catch phrases, locations, concepts, artwork, graphics, animation, sounds, musical compositions, audio-visual effects, text, screen displays, methods of operation, moral rights, “applets” incorporated into the Game, and any related documentation) are owned by Game or its licensors.</p>
      <p style={{ marginBottom: '5px' }}>(2) The Developer has the ownership, licensing rights and all other rights to all content related to the game. You have the right to use in-game data (items, game currency, etc) or similar content in the game in accordance with Terms of Service. However, you do not own the copyrights and other rights about the in-game data you possess. You are not allowed to transfer, sell, donate, or attempt to trade virtual property outside of the game is not permitted except in the case of acquiring a written approval from the Developer and any actions violating ownership may be subject to penalty in accordance with Terms of Service and Rules of Conduct.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>5. Disclaimers & Indemnifications</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p style={{ marginBottom: '25px' }}>By using the Service, you agree to defend, protect, indemnify and hold harmless to the Developer from any claims, lawsuits, damages, losses, liabilities and expenses (including attorneys' fees) arising out of or in conjunction to your use, misuse of the Service and violation of the Developer's policy. The Developer reserves the right to assume exclusive defense and control over all matters subject to indemnification by users.</p>
      <p style={{ marginBottom: '5px' }}>(1) The Developer shall be exempted from any liability for any interruption of the service, the use of the service, or termination of the contract due to user's fault.</p>
      <p style={{ marginBottom: '5px' }}>(2) The Developer shall be exempted from any liability if the telecommunications providers cease or fail to provide the telecommunication service. So users shall be liable for any damages incurred during failure of telecommunication service unless there are intentional or gross negligence from the Developer.</p>
      <p style={{ marginBottom: '5px' }}>(3) The Developer shall be exempted from liability of any unavoidable reasons that caused Service failure, such as maintenance, replacement, regular inspection, construction, etc. unless there are intentional or material negligence of the Developer.</p>
      <p style={{ marginBottom: '5px' }}>(4) The Developer is exempted from liability for any problems caused by the user's computer or problems related to the network environment without any intention or serious negligence of the Developer.</p>
      <p style={{ marginBottom: '5px' }}>(5) The Developer shall be exempted from liability for the contents of the information, data, facts, reliability, accuracy, etc. posted or transmitted by the user or a third party in the service or on the website of the Developer, unless there are intentional or gross negligence of the Developer.</p>
      <p style={{ marginBottom: '5px' }}>(6) You are solely responsible for your interactions with other users and/or third parties when using the Service. We may, at our sole discretion, attempt to mediate disputes between users, but we are not obliged to become involved or become liable in any way with such disputes.</p>
      <p style={{ marginBottom: '5px' }}>(7) In the case of Service provided free of charge by the Developer, the Developer shall not be liable for damages unless there are intentional or material negligence of the Developer.</p>
      <p style={{ marginBottom: '5px' }}>(8) Some of the services may be provided by other service providers, and the Developer is exempted from liability of the damages caused by other service providers, unless there are intentional or material negligence of the Developer.</p>
      <p style={{ marginBottom: '5px' }}>(9) The Developer shall not be liable when users fail to receive expected result of the in-game items and the Developer is not liable for any loss involved when making selection or using the Service, unless there are intentional or material negligence of the Developer.</p>
      <p style={{ marginBottom: '5px' }}>(10) The Developer shall be exempted from liability for loss of in-game assets, items, BP, crates from the user, unless there are intentional or gross negligence of the Developer.</p>
      <p style={{ marginBottom: '5px' }}>(11) The Developer may limit the use of the service from users in accordance with the relevant laws, government policies, and will be exempted from any liability for the use of the service caused by such limitations.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>6. Disclaimer of Warranties</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p style={{ marginBottom: '25px' }}>THE GAME AND SERVICES AND CONTENT ARE PROVIDED ON AN “AS IS” BASIS AND AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE GHOST HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES OR CONDITIONS OF ANY KIND, WRITTEN OR ORAL, EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY OF TITLE, NONINFRINGEMENT, MERCHANTABILITY, QUIET ENJOYMENT, ACCURACY, OR FITNESS FOR A PARTICULAR PURPOSE.</p>

      <p>Without limiting any of the foregoing, the Game does not ensure continuous, error-free operation of Game Services or Your Account. Some states or countries do not allow the disclaimer of implied warranties, and the foregoing disclaimer may not be applicable to You. This warranty gives You specific legal rights, and You may also have other legal rights that vary from state to state or from country to country.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>7. Limitation of Liability</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p style={{ marginBottom: '25px' }}>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE DEVELOPER BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO LOSS OF BUSINESS, LOSS OF DATA, LOSS OF BUSINESS RIGHTS, LOSS OF PROFITS) AND DAMAGE (INCLUDING BUT NOT LIMITED TO INTERRUPTION OF WORK OPERATION, HARDWARE MALFUNCTION, OR OTHER COMMERCIAL DAMAGE OR LOSS ARISING OUT OF YOUR USE OR MISMUSE OF THE SERVICE. THIS IS ALSO TRUE WHETHER OR NOT THE DEVELOPER ALREADY KNEW OR HAD TO KNOW ABOUT THE POSSBILITY OF THE DAMAGES.</p>

      <p>DEVELOPER SHALL NOT BE LIABLE FOR ANY INTERRUPTION OF SERVICE, INCLUDING, BUT NOT LIMITED TO, ISP FAILURE, SOFTWARE OR HARDWARE FAILURE, OR ANY OTHER SITUATION THAT MAY RESULT IN DATA LOSS OR SERVICE INTERRUPTION. IN NO EVENT THE DEVELOPER BE LIABLE FOR ANY DAMAGES BEYOND THE AMOUNT PAID FOR THE PURCHASE OF THE GAME. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS ARE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW IN SUCH COUNTRIES OR JURISDICTIONS.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>8. Period and Termination</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p>These Terms are effective unless and until terminated by either you or the Developer. The Developer and the user may terminate these terms at any time without prior notice. Termination by the Developer will come into effect immediately upon the termination or deletion of your account, which can occur at any time in the Developer's sole discretion. You may terminate Terms of Service by not using the Game, and you must stop using the Game and delete the Game immediately upon termination of Terms of Service.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>9. Transfer of Terms and Conditions</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p>The Developer may assign any or all of these terms and conditions to any third party at any time. You shall not transfer these terms without the Developer's prior written consent, and any transfer without written consent from the Developer will not count.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>10. Grievance Handling and Dispute Resolution</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p style={{ marginBottom: '5px' }}>(1) The Developer shall inform about the ways that users can present their opinions or complaints considering any of the inconveniences implied to the users. The Developer operates as a dedicated organization to handle such users' opinions and complaints.</p>
      <p style={{ marginBottom: '5px' }}>(2) If a dispute arises between the Developer and the user and the Third Party Dispute Arbitration Body becomes involved in adjusting such grievance, the Developer shall faithfully demonstrate the measures taken on the user, including restrictions of usage, and may comply with the result of the arbitration.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>11. Governing Law</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p>All lawsuits related to these Terms and Conditions are subject to the jurisdiction of the courts pursuant to relevant laws such as the Code of Civil Procedure. The applicable local laws shall only affect these Terms to the extent required by the applicable jurisdiction, and these Terms shall be construed as to make their terms and conditions as effective as possible. You must comply with all local laws to the extent that applicable local law applies.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>12. Class Action Waiver</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p>You agree not to participate in, including but not limited to, a class or representative action, private attorney general action, or collective arbitration related to the Game or Service or this Agreement. You also agree not to seek to combine any action or arbitration related to the Game or Service or this Agreement with any other action or arbitration without the consent of all parties to this Agreement and all other actions or arbitrations.</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>13. Copyright Notice</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p style={{ marginBottom: '25px' }}>If you think any materials from Game infringe your copyright-protected work under the copyright law, you may provide an infringement notice to the Developer's designated agent as set forth below. Also, to make your notice become effective, you must include the following information:</p>

      <p style={{ marginBottom: '5px' }}>(1) Sufficient details to enable us to identify the work claimed to be infringed;</p>
      <p style={{ marginBottom: '5px' }}>(2) Your contact information, including your address and email address;</p>
      <p style={{ marginBottom: '5px' }}>(3) A statement that you have a good faith belief that the disputed is not authorized by the copyright owner, its agent, or the law;</p>
      <p style={{ marginBottom: '5px' }}>(4) A statement, under penalty of perjury, that the information in the notification is accurate and that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</p>
      <p style={{ marginBottom: '5px' }}>(5) Your physical or electronic signature;</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p style={{ marginBottom: '5px' }}>The contact information of Developer's designated agent is as follows:</p>
      <p style={{ marginBottom: '5px' }}>Email: gameplier.team@gmail.com</p>

      <div // spacing
        style={{ height: '25px' }}
      />

      <h3>14. Other policies</h3>

      <div // spacing
        style={{ height: '25px' }}
      />

      <p style={{ marginBottom: '5px' }}>(1) The Developer may use Player-created Content, Rules of Conduct and Privacy Policy to cover specific details that are not covered in Terms of Service in order to protect user interests and maintain order in the game.</p>
      <p style={{ marginBottom: '5px' }}>(2) By agreeing to conditions set in Terms of Service, you also agree to be bound by the terms and conditions set in Rules of Conduct and Privacy Policy.</p>
      <p style={{ marginBottom: '5px' }}>(3) Privacy Policy and Rules of Conduct can be found at any time in <Link to='/' style={{color: 'white'}}>www.theghostgame.com</Link></p>

      <div // spacing
        style={{ height: '35px' }}
      />

    </div>
  );
}