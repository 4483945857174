import React, { useEffect, useState, useRef } from 'react';
import './Home.css';
import './Cards.css';
import CardItem from './CardItem';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

// https://github.com/briancodex/react-website-v1/tree/master

const GALLERY_THUMBNAILS = [
  '/images/MultiplayerSlideThumb.png',
  '/images/MapReleasesSlideThumb.png',
  '/images/ProgressionSlideThumb.png',
  '/images/CompetitionSlideThumb.png',
]
const GALLERY_THUMBNAILS_WEBP = [
  '/images/MultiplayerSlideThumb.webp',
  '/images/MapReleasesSlideThumb.webp',
  '/images/ProgressionSlideThumb.webp',
  '/images/CompetitionSlideThumb.webp',
]
const GALLERY_IMAGES = [
  '/images/MultiplayerSlide.png',
  '/images/MapReleasesSlide.png',
  '/images/ProgressionSlide.png',
  '/images/CompetitionSlide.png',
]
const GALLERY_IMAGES_WEBP = [
  '/images/MultiplayerSlide.webp',
  '/images/MapReleasesSlide.webp',
  '/images/ProgressionSlide.webp',
  '/images/CompetitionSlide.webp',
]
const GALLERY_TITLES = [
  'Multiplayer Survival Horror',
  'Map Releases',
  'Progression',
  'Events & Competition',
]
const GALLERY_DESCRIPTIONS = [
  'Cooperate with others to escape abandoned locations against enemy AI',//'Escape together with friends or other players',
  'Each new map is unique, with short and replayable gameplay loop',
  'Earn XP by completing objectives, unlock and level up your perks, unlock new skins and more',
  'Participate in events, climb up the leaderboard in competitive game mode and receive unique rewards!',
]

function Home() {
  const galleryImageRefs = useRef([]);
  const [galleryIndex, setGalleryIndex] = useState(0);

  useEffect(() => {
    document.title = 'The Ghost | Multiplayer Survival Horror';
    window.scrollTo(0, 0)
  }, [])

  const addToGalleryImageRefs = (el) => {
    if (el && !galleryImageRefs.current.includes(el)) {
      // console.log('added: ', el)
      galleryImageRefs.current.push(el);
    }
  };

  const handleGalleryUp = () => {
    // hiding image until it's loaded
    galleryImageRefs.current.forEach((img, index) => {
      if (img) {
        img.style.visibility = 'hidden'
      }
    });

    setGalleryIndex((prevIndex) => (prevIndex + 1) % GALLERY_TITLES.length);
  };
  const handleGalleryDown = () => {
    // hiding image until it's loaded
    galleryImageRefs.current.forEach((img, index) => {
      if (img) {
        img.style.visibility = 'hidden'
      }
    });

    setGalleryIndex((prevIndex) => (prevIndex - 1 + GALLERY_TITLES.length) % GALLERY_TITLES.length);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#000000', height: '6603px' }}>
      <div className='home-root'>
        <ParallaxProvider>
          <div style={{ position: 'absolute', width: '1200px', height: '825px' }}>

            {/* <ParallaxBanner // hospital bg
              layers={[{ image: '/images/HospitalParallax.png', speed: -15 }]}
              style={{ width: '1200px', height: '825px', objectFit: 'cover' }}
            >
              <div style={{ // bg shadow
                position: 'absolute',
                marginTop: '-825px',
                width: '1200px',
                height: '825px',
                objectFit: 'cover',
                boxShadow: 'inset 0px 0px 100px 75px black',
              }} />

              <div style={{ // black overlay
                position: 'absolute',
                marginTop: '-825px',
                width: '1200px',
                height: '825px',
                objectFit: 'cover',
                backgroundColor: 'rgba(0, 0, 0, 0.35)'
              }} />
            </ParallaxBanner> */}

            {/* <Parallax // hospital bg
              speed={-100}
              easing={[0, .5, .75, 1]} // https://react-scroll-parallax.damnthat.tv/docs/examples/easing
            >
              <img src='/images/HospitalParallax.png' alt='' style={{
                position: 'absolute',
                marginTop: '-825px',
                width: '1200px', // 2168px
                height: '825px', // 1133px
                objectFit: 'cover',
              }} />
              <div style={{ // bg shadow
                position: 'absolute',
                marginTop: '-825px',
                width: '1200px',
                height: '825px',
                objectFit: 'cover',
                boxShadow: 'inset 0px 0px 100px 75px black',
              }} />
              <div style={{ // black overlay
                position: 'absolute',
                marginTop: '-825px',
                width: '1200px',
                height: '825px',
                objectFit: 'cover',
                backgroundColor: 'rgba(0, 0, 0, 0.35)'
              }} />
            </Parallax> */}

            <Parallax // hospital bg
              speed={-15}
              style={{ position: 'absolute' }}
            >
              <picture>
                <source type="image/webp" srcset='/images/HospitalParallax.webp' />
                <source type="image/png" srcset='/images/HospitalParallax.png' />
                <img src='/images/HospitalParallax.png' alt='' style={{
                  width: '1200px', // 2168px
                  height: '825px', // 1133px
                  objectFit: 'cover',
                }} />
              </picture>
            </Parallax>
            <Parallax // black overlay
              speed={-15}
              style={{ position: 'absolute' }}
            >
              <div style={{
                width: '1200px',
                height: '825px',
                objectFit: 'cover',
                backgroundColor: 'rgba(0, 0, 0, 0.35)'
              }} />
            </Parallax>
            <Parallax // bg shadow
              speed={-15}
              style={{ position: 'absolute' }}
            >
              <div style={{ // bg shadow
                width: '1200px',
                height: '825px',
                objectFit: 'cover',
                boxShadow: 'inset 0px 0px 100px 75px black',
              }} />
            </Parallax>
          </div>

          <div className='home-container'>
            {/* padding */}
            <div style={{
              height: '125px'
            }} />

            <div className='download-container-top'>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <a href='https://horror-ponds.itch.io/cursedapartment' aria-label='Game on itch.io' target="_blank" rel='noreferrer'>
                  <img width={148} height={45} src='/icons/itchio_icon.svg' alt='' />
                </a>
              </div>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <a href='https://play.google.com/store/apps/details?id=com.Gameplier.TheGhost' aria-label='Game on Google Play' target="_blank" rel='noreferrer'>
                  <img width={154} height={45} src='/icons/google_play_icon.png' alt='' />
                </a>
                <p style={{ fontFamily: 'AnonymousPro', paddingTop: '5px' }}>30M+ installs</p>
              </div>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <a href='https://apps.apple.com/us/app/the-ghost-multiplayer-horror/id1557035248' aria-label='Game on App Store' target="_blank" rel='noreferrer'>
                  <img width={134} height={45} src='/icons/app_store_icon.svg' alt='' />
                </a>
                <p style={{ fontFamily: 'AnonymousPro', paddingTop: '5px' }}>10M+ installs</p>
              </div>
            </div>

            <div style={{ width: '100%', height: '650px', position: 'relative' }}>
              <div className='home-gallery-description'>
                <p style={{ fontFamily: 'AnonymousPro-Bold', fontSize: '24px' }}>{GALLERY_TITLES[galleryIndex % GALLERY_TITLES.length]}</p> {/*  Multiplayer Survival Horror */}
                <p style={{ fontFamily: 'AnonymousPro', paddingLeft: '2px', marginTop: '15px', fontSize: '20px', lineHeight: '28px', width: '600px', height: '55px' }}>{GALLERY_DESCRIPTIONS[galleryIndex % GALLERY_DESCRIPTIONS.length]}</p> {/*  Escape together with friends or other players */}

                {/* padding */}
                <div style={{
                  height: '5px'
                }} />
              </div>

              <div className='home-gallery-container'>
                <div style={{
                  width: '600px',
                  height: '337px',
                  boxShadow: '0px 0px 2px 5px rgba(123, 123, 123, 0.25)'
                }}>
                  <picture>
                    <source type="image/webp" srcset={`${GALLERY_IMAGES_WEBP[galleryIndex % GALLERY_IMAGES_WEBP.length]}`} />
                    <source type="image/png" srcset={`${GALLERY_IMAGES[galleryIndex % GALLERY_IMAGES.length]}`} />
                    <img src={`${GALLERY_IMAGES[galleryIndex % GALLERY_IMAGES.length]}`}
                      alt=''
                      ref={addToGalleryImageRefs}
                      onLoad={(e) => { e.target.style.visibility = 'visible'; }}
                      width={600}
                      height={337}
                      fetchPriority='high'
                      style={{
                        visibility: 'hidden'
                      }} />
                  </picture>
                </div>

                <div className='home-gallery-controller'>
                  <button onClick={handleGalleryUp} aria-label='Arrow Up' style={{ width: '160px', height: '80px', cursor: 'pointer', backgroundColor: 'transparent', border: 'transparent', opacity: 0.25 }}>
                    <picture>
                      <source type="image/webp" srcset='/images/SmallArrowLeft.webp' />
                      <source type="image/png" srcset='/images/SmallArrowLeft.png' />
                      <img src='/images/SmallArrowLeft.png' alt='' width={16} height={32} style={{ transform: 'rotate(90deg)' }} />
                    </picture>
                  </button>

                  <div style={{
                    width: '100px',
                    height: '56px',
                    boxShadow: '0px 0px 1px 3px rgba(123, 123, 123, 0.25)'
                  }}>
                    <picture>
                      <source type="image/webp" srcset={`${GALLERY_THUMBNAILS_WEBP[(galleryIndex + 1) % GALLERY_THUMBNAILS_WEBP.length]}`} />
                      <source type="image/png" srcset={`${GALLERY_THUMBNAILS[(galleryIndex + 1) % GALLERY_THUMBNAILS.length]}`} />
                      <img src={`${GALLERY_THUMBNAILS[(galleryIndex + 1) % GALLERY_THUMBNAILS.length]}`}
                        alt=''
                        ref={addToGalleryImageRefs}
                        onLoad={(e) => { e.target.style.visibility = 'visible'; }}
                        width={100}
                        height={56}
                        style={{
                          visibility: 'hidden'
                        }} />
                    </picture>
                  </div>

                  {/* spacing */}
                  <div style={{
                    height: '15px'
                  }} />

                  <div style={{
                    width: '160px',
                    height: '90px',
                    boxShadow: '0px 0px 2px 4px rgba(255, 0, 0, 0.25)'
                  }}>
                    <picture>
                      <source type="image/webp" srcset={`${GALLERY_THUMBNAILS_WEBP[galleryIndex % GALLERY_THUMBNAILS_WEBP.length]}`} />
                      <source type="image/png" srcset={`${GALLERY_THUMBNAILS[galleryIndex % GALLERY_THUMBNAILS.length]}`} />
                      <img src={`${GALLERY_THUMBNAILS[galleryIndex % GALLERY_THUMBNAILS.length]}`}
                        alt=''
                        ref={addToGalleryImageRefs}
                        onLoad={(e) => { e.target.style.visibility = 'visible'; }}
                        width={160}
                        height={90}
                        style={{
                          visibility: 'hidden'
                        }} />
                    </picture>
                  </div>

                  {/* spacing */}
                  <div style={{
                    height: '15px'
                  }} />

                  <div style={{
                    width: '100px',
                    height: '56px',
                    boxShadow: '0px 0px 1px 3px rgba(123, 123, 123, 0.25)'
                  }}>
                    <picture>
                      <source type="image/webp" srcset={`${GALLERY_THUMBNAILS_WEBP[(galleryIndex - 1 + GALLERY_THUMBNAILS_WEBP.length) % GALLERY_THUMBNAILS_WEBP.length]}`} />
                      <source type="image/png" srcset={`${GALLERY_THUMBNAILS[(galleryIndex - 1 + GALLERY_THUMBNAILS.length) % GALLERY_THUMBNAILS.length]}`} />
                      <img src={`${GALLERY_THUMBNAILS[(galleryIndex - 1 + GALLERY_THUMBNAILS.length) % GALLERY_THUMBNAILS.length]}`}
                        alt=''
                        ref={addToGalleryImageRefs}
                        onLoad={(e) => { e.target.style.visibility = 'visible'; }}
                        width={100}
                        height={56}
                        style={{
                          visibility: 'hidden'
                        }} />
                    </picture>
                  </div>

                  <button onClick={handleGalleryDown} aria-label='Arrow Down' style={{ width: '160px', height: '80px', cursor: 'pointer', backgroundColor: 'transparent', border: 'transparent', opacity: 0.25 }}>
                    <picture>
                      <source type="image/webp" srcset='/images/SmallArrowLeft.webp' />
                      <source type="image/png" srcset='/images/SmallArrowLeft.png' />
                      <img src='/images/SmallArrowLeft.png' alt='' width={16} height={32} style={{ transform: 'rotate(-90deg)' }} />
                    </picture>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div style={{ // leagues
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            top: '1115px',
            marginLeft: '283px',
          }}>
            <picture>
              <source type="image/webp" srcset='/images/LeaguesRanked.webp' />
              <source type="image/png" srcset='/images/LeaguesRanked.png' />
              <img src='/images/LeaguesRanked.png' alt='' style={{
                position: 'absolute',
                width: '426px',
                height: '426px',
                zIndex: '3',
                opacity: 0.75
              }} />
            </picture>
          </div>

          <div style={{ // dashed maps
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            top: '1075px',
            marginTop: '25px',
            marginBottom: '25px',
            marginLeft: '-100px',
          }}>
            <picture>
              <source type="image/webp" srcset='/images/DashedMaps.webp' />
              <source type="image/png" srcset='/images/DashedMaps.png' />
              <img src='/images/DashedMaps.png' alt='' style={{
                position: 'absolute',
                width: '1200px', // 1492px
                height: '485px',
                objectFit: 'cover',
                zIndex: '2',
                boxShadow: '0px 0px 40px 40px black'
              }} />
            </picture>

            <div style={{
              position: 'absolute',
            }}>
              <div style={{ // bg shadow
                position: 'absolute',
                width: '1200px',
                height: '485px',
                objectFit: 'cover',
                zIndex: '2',
                boxShadow: 'inset 25px 10px 25px 0px black',
              }} />
              <div style={{ // bg shadow
                position: 'absolute',
                width: '1200px',
                height: '485px',
                objectFit: 'cover',
                zIndex: '2',
                boxShadow: 'inset -25px -10px 25px 0px black',
              }} />
            </div>
          </div>

          <div className='home-container'>
            <p style={{ fontFamily: 'AnonymousPro', fontSize: '32px', textAlign: 'center', paddingTop: '100px', zIndex: '10' }}>COMPETITIVE MODE</p>
            <p style={{ fontFamily: 'AnonymousPro', fontSize: '20px', paddingTop: '30px', paddingLeft: '70px', zIndex: '10' }}>Competitive mode with 4 maps on rotation</p>

            {/* padding */}
            <div style={{
              height: '385px',
              paddingTop: '50px'
            }} />
          </div>

          <div style={{ position: 'absolute', width: '1200px', height: '800px', top: '1700px' }}>
            <Parallax
              speed={-15}
              style={{ position: 'absolute' }}
            >
              <picture>
                <source type="image/webp" srcset='/images/ApartmentsParallaxV2.webp' />
                <source type="image/png" srcset='/images/ApartmentsParallaxV2.png' />
                <img src='/images/ApartmentsParallaxV2.png' alt='' style={{ // apartments bg
                  width: '1200px', // 2168px
                  height: '800px', // 1133px
                  objectFit: 'cover',
                  marginLeft: '-110px',
                }} />
              </picture>
            </Parallax>
            <Parallax
              speed={-15}
              style={{ position: 'absolute' }}
            >
              <div style={{ // bg shadow
                width: '1200px',
                height: '810px',
                marginLeft: '-110px',
                objectFit: 'cover',
                boxShadow: 'inset 0px 0px 100px 75px black',
              }} />
            </Parallax>
          </div>
          {/* <Parallax translateY={['700px', '1000px']} easing={[0, .5, .75, 1]}>
            <img src='/images/ApartmentsParallaxV2.png' alt='' style={{ // apartments bg
              position: 'absolute',
              top: '-700px', // 1650px
              width: '1200px', // 2168px
              height: '800px', // 1133px
              marginLeft: '10px',
              objectFit: 'cover'
            }} />
            <div style={{ // bg shadow
              position: 'absolute',
              top: '-700px',
              width: '1200px',
              height: '800px',
              marginLeft: '10px',
              objectFit: 'cover',
              boxShadow: 'inset 0px 0px 100px 75px black',
            }} />
          </Parallax> */}

          <div className='home-container'>
            <p style={{ fontFamily: 'AnonymousPro', fontSize: '32px', textAlign: 'center', paddingTop: '265px', zIndex: '10' }}>SKINS</p>
            <p style={{ fontFamily: 'AnonymousPro', fontSize: '20px', paddingTop: '30px', paddingLeft: '70px', zIndex: '10' }}>Skins with patterns and wear rating</p>

            {/* skin pattern & wear gif */}
            <img src='/gifs/skinwear.gif' alt='' onLoad={() => { console.log("skinwear loaded!") }} style={{
              marginTop: '50px',
              marginLeft: '450px',
              width: '348px',
              height: '350px',
            }} />

            {/* padding */}
            <div style={{
              height: '170px'
            }} />
          </div>

          {/* <div style={{ position: 'absolute', width: '1200px', height: '850px', top: '2525px' }}>
            <Parallax
              speed={-15}
              style={{ position: 'absolute' }}
            >
              <img src='/images/SewersParallaxV2.png' alt='' style={{ // sewers bg
                width: '1200px', // 2168px
                height: '850px', // 1133px
                objectFit: 'cover',
                marginLeft: '-100px',
                boxShadow: '0px 0px 40px 40px black',
                opacity: 0.8
              }} />
            </Parallax>
            <Parallax
              speed={-15}
              style={{ position: 'absolute' }}
            >
              <div style={{ // bg shadow
                width: '1200px',
                height: '860px',
                marginLeft: '-100px',
                objectFit: 'cover',
                boxShadow: 'inset 0px 50px 100px 75px black',
              }} />
            </Parallax>
            <Parallax
              speed={-15}
              style={{ position: 'absolute' }}
            >
              <div style={{ // bg shadow
                width: '1200px',
                height: '860px',
                marginLeft: '-100px',
                objectFit: 'cover',
                boxShadow: 'inset 0px -50px 100px 75px black',
              }} />
            </Parallax>
          </div> */}

          <div style={{ // sewers bg
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            top: '2585px',
            marginLeft: '-100px'
          }}>
            <picture>
              <source type="image/webp" srcset='/images/SewersParallaxV2.webp' />
              <source type="image/png" srcset='/images/SewersParallaxV2.png' />
              <img src='/images/SewersParallaxV2.png' alt='' style={{
                position: 'absolute',
                width: '1200px', // 2168px
                height: '850px', // 1133px
                objectFit: 'cover',
                boxShadow: '0px 0px 40px 40px black',
                opacity: 0.8
              }} />
            </picture>

            <div style={{
              position: 'absolute',
            }}>
              <div style={{ // bg shadow
                position: 'absolute',
                width: '1200px',
                height: '850px',
                objectFit: 'cover',
                boxShadow: 'inset 0px 50px 100px 75px black',
              }} />
              <div style={{ // bg shadow
                position: 'absolute',
                width: '1200px',
                height: '850px',
                objectFit: 'cover',
                boxShadow: 'inset 0px -50px 100px 75px black',
              }} />
            </div>
          </div>

          <div style={{ // perks
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            top: '2734px',
            marginLeft: '-300px', // 170px
          }}>
            <picture>
              <source type="image/webp" srcset='/images/TheGhostPerksV2.webp' />
              <source type="image/png" srcset='/images/TheGhostPerksV2.png' />
              <img src='/images/TheGhostPerksV2.png' alt='' style={{
                position: 'absolute',
                width: '1600px',
                height: '560px',
                zIndex: '2',
                objectFit: 'scale-down',
              }} />
            </picture>
          </div>

          <div className='home-container'>
            <p style={{ fontFamily: 'AnonymousPro', fontSize: '32px', textAlign: 'center', paddingTop: '125px', zIndex: '10' }}>PERKS</p>
            <p style={{ fontFamily: 'AnonymousPro', fontSize: '20px', paddingTop: '45px', paddingLeft: '70px', zIndex: '10' }}>More than 40 perks available to diversify your gameplay,</p>
            <p style={{ fontFamily: 'AnonymousPro', fontSize: '20px', paddingTop: '5px', paddingLeft: '70px', zIndex: '10' }}>each with unique advantages</p>

            {/* padding */}
            <div style={{
              height: '800px'
            }} />
          </div>

          <div className='home-container'>
            <p style={{ fontFamily: 'AnonymousPro', fontSize: '32px', textAlign: 'center', zIndex: '10' }}>GAMEPLAY SCREENSHOTS</p>
          </div>

          <div className='cards'>
            <div className='cards__container'>
              <div className='cards__wrapper'>
                <ul className='cards__items'>
                  <CardItem
                    src='/images/GameplayScreenshot3Thumb.png'
                    webpSrc='/images/GameplayScreenshot3Thumb.webp'
                    zoomSrc='/images/GameplayScreenshot3.png' // TODO incorrect
                    label='Gameplay Screenshot 3'
                    path='/'
                  />
                  <CardItem
                    src='/images/GameplayScreenshot2Thumb.png'
                    webpSrc='/images/GameplayScreenshot2Thumb.webp'
                    zoomSrc='/images/GameplayScreenshot2.png' // TODO incorrect
                    label='Gameplay Screenshot 2'
                    path='/'
                  />
                  <CardItem
                    src='/images/GameplayScreenshot1Thumb.png'
                    webpSrc='/images/GameplayScreenshot1Thumb.webp'
                    zoomSrc='/images/GameplayScreenshot1.png' // TODO incorrect
                    label='Gameplay Screenshot 1'
                    path='/'
                  />
                </ul>
                <ul className='cards__items'>
                  <CardItem
                    src='/images/GameplayScreenshot4Thumb.png'
                    webpSrc='/images/GameplayScreenshot4Thumb.webp'
                    zoomSrc='/images/GameplayScreenshot4.png' // TODO incorrect
                    label='Gameplay Screenshot 4'
                    path='/'
                  />
                  <CardItem
                    src='/images/GameplayScreenshot5Thumb.png'
                    webpSrc='/images/GameplayScreenshot5Thumb.webp'
                    zoomSrc='/images/GameplayScreenshot5.png' // TODO incorrect
                    label='Gameplay Screenshot 5'
                    path='/'
                  />
                  <CardItem
                    src='/images/GameplayScreenshot6Thumb.png'
                    webpSrc='/images/GameplayScreenshot6Thumb.webp'
                    zoomSrc='/images/GameplayScreenshot6.png' // TODO incorrect
                    label='Gameplay Screenshot 6'
                    path='/'
                  />
                </ul>
              </div>
            </div>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '75px'
          }}>
            <picture>
              <source type="image/webp" srcset='/images/PegiRating.webp' />
              <source type="image/png" srcset='/images/PegiRating.png' />
              <img src='/images/PegiRating.png' alt='' style={{
                width: '221px',
                height: '97px',
              }} />
            </picture>
          </div>

          <div className='home-container'>
            <p style={{ fontFamily: 'AnonymousPro', fontSize: '42px', textAlign: 'center', zIndex: '10', color: '#cc0036' }}>LATEST MAP RELEASE</p>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '75px',
            marginBottom: '40px'
          }}>
            <picture>
              <source type="image/webp" srcset='/images/TheGhostLaunchScreen.webp' />
              <source type="image/png" srcset='/images/TheGhostLaunchScreen.png' />
              <img src='/images/TheGhostLaunchScreen.png' alt='' style={{
                width: '2560px',
                height: '400px',
                maxWidth: '950px',
                objectFit: 'cover'
              }} />
            </picture>

            <div style={{
              position: 'absolute',
              marginRight: '950px'
            }}>
              {/* bg shadow */}
              <div style={{
                position: 'absolute',
                width: '2560px',
                height: '400px',
                maxWidth: '950px',
                objectFit: 'cover',
                boxShadow: 'inset 0px 0px 10px 0px black',
              }} />
              {/* bg shadow */}
              <div style={{
                position: 'absolute',
                width: '2560px',
                height: '400px',
                maxWidth: '950px',
                objectFit: 'cover',
                boxShadow: 'inset 0px 0px 10px 0px black',
              }} />
            </div>
          </div>

          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '180px', fontSize: '32px', color: '#cc0036' }}>Cursed Apartment</p>
          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '182px', marginTop: '45px', fontSize: '18px', color: 'white' }}>You wake up in your apartment's room. A voice from the recorder tells you that you're</p>
          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '182px', marginTop: '5px', fontSize: '18px', color: 'white' }}>trapped in a cursed apartment, infested with anomalies.</p>

          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '182px', marginTop: '35px', fontSize: '18px', color: 'white' }}>In Cursed Apartment you need to be attentive to not overlook any anomalies on floors.</p>
          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '182px', marginTop: '5px', fontSize: '18px', color: 'white' }}><span style={{ fontFamily: 'AnonymousPro', color: '#ffd700' }}>Use the elevator to reach floor 1 and escape.</span> When you miss an anomaly, elevator will</p>
          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '182px', marginTop: '5px', fontSize: '18px', color: 'white' }}>glitch and take you back to the highest floor.</p>

          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '182px', marginTop: '35px', fontSize: '18px', color: 'white' }}><span style={{ fontFamily: 'AnonymousPro', color: '#cc0036' }}>PLAYTIME:</span> 30 - 60 minutes</p>

          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '180px', marginTop: '35px', fontSize: '32px', color: '#cc0036' }}>GAMEMODES</p>
          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '182px', marginRight: '182px', marginTop: '20px', fontSize: '18px', textAlign: 'center', color: 'white' }}>Choose <span style={{ fontFamily: 'AnonymousPro', fontWeight: 'bold' }}>Exit 8</span> game mode - press up when there's anomaly, press down when no anomalies</p>

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '35px',
          }}>
            {/* doesn't work on Safari */}
            {/* <video autoplay loop muted playsinline>
              <source src="/gifs/ElevatorButton.mp4" type="video/mp4" />
              <source src="/gifs/ElevatorButton.webm" type="video/webm" />
            </video> */}
            <img src='/gifs/ElevatorButton.gif' alt='' style={{
              width: '600px',
              height: '338px',
            }} />
          </div>

          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '182px', marginRight: '182px', marginTop: '35px', fontSize: '18px', textAlign: 'center', color: 'white' }}>Choose <span style={{ fontFamily: 'AnonymousPro', fontWeight: 'bold' }}>Escape</span> game mode - remove anomalies by capturing them with your camera</p>

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '35px',
          }}>
            {/* doesn't work on Safari */}
            {/* <video autoplay loop muted playsinline>
              <source src="/gifs/AnomalyCapture.mp4" type="video/mp4" />
              <source src="/gifs/AnomalyCapture.webm" type="video/webm" />
            </video> */}
            <img src='/gifs/AnomalyCapture.gif' alt='' style={{
              width: '600px',
              height: '338px',
            }} />
          </div>

          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '180px', marginTop: '40px', fontSize: '32px', color: '#cc0036' }}>FEATURES</p>
          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '195px', marginTop: '20px', fontSize: '18px', color: 'white' }}>• Anomalies</p>
          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '195px', marginTop: '10px', fontSize: '18px', color: 'white' }}>• Deadly monsters</p>
          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '195px', marginTop: '10px', fontSize: '18px', color: 'white' }}>• Multiplayer with proximity voice chat</p>
          <p style={{ fontFamily: 'AnonymousPro', marginLeft: '195px', marginTop: '10px', fontSize: '18px', color: 'white' }}>• Short loop gameplay</p>

          <picture>
            <source type="image/webp" srcset='/images/DownloadBoldText.webp' />
            <source type="image/png" srcset='/images/DownloadBoldText.png' />
            <img src='/images/DownloadBoldText.png' alt='' style={{
              width: '141px',
              height: '21px',
              marginLeft: '180px',
              marginTop: '50px'
            }} />
          </picture>

          {/* padding */}
          <div style={{
            height: '35px'
          }} />

          <div className='download-container-bottom'>
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <a href='https://horror-ponds.itch.io/cursedapartment' target="_blank" aria-label='Game on itch.io' rel='noreferrer'>
                <img width={148} height={45} src='/icons/itchio_icon.svg' alt='' />
              </a>
            </div>
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <a href='https://play.google.com/store/apps/details?id=com.Gameplier.TheGhost' aria-label='Game on Google Play' target="_blank" rel='noreferrer'>
                <img width={154} height={45} src='/icons/google_play_icon.png' alt='' />
              </a>
            </div>
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <a href='https://apps.apple.com/us/app/the-ghost-multiplayer-horror/id1557035248' aria-label='Game on App Store' target="_blank" rel='noreferrer'>
                <img width={134} height={45} src='/icons/app_store_icon.svg' alt='' />
              </a>
            </div>
          </div>

          {/* padding */}
          <div style={{
            height: '41px'
          }} />
        </ParallaxProvider>
      </div>
    </div>
  );
}

export default Home;
