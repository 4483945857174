import React, { useState } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutUs from './components/pages/AboutUs';
import TermsOfService from './components/pages/TermsOfService';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import RulesOfConduct from './components/pages/RulesOfConduct';
import Footer from './components/Footer';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

function App() {
  const [cookieConsent, setCookieConsent] = useState(getCookieConsentValue());

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/terms_of_service' element={<TermsOfService />} />
          <Route path='/privacy_policy' element={<PrivacyPolicy />} />
          <Route path='/rules_of_conduct' element={<RulesOfConduct />} />
        </Routes>

        <div // black background
          style={{ position: 'absolute', color: 'black', backgroundColor: 'black', width: '100%', height: '100%', zIndex: '-1' }}
        />

        <div style={{ height: `${cookieConsent ? ('0px') : ('54px')}` }} />

        <div style={{ width: '100%', position: 'absolute', bottom: 0 }}>
          <Footer />
          <div style={{ height: `${cookieConsent ? ('0px') : ('54px')}` }} />
        </div>

        <CookieConsent
          expires={150}
          onAccept={(acceptedByScrolling) => {
            if (acceptedByScrolling) {
              // triggered if user scrolls past threshold
              // alert("Accept was triggered by user scrolling");
            } else {
              // alert("Accept was triggered by clicking the Accept button");
            }
            setCookieConsent(true);
          }}
          onDecline={() => {
            // alert("nay!");
            setCookieConsent(true);
          }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </Router>
    </>
  );
}

export default App;
