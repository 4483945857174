import React, { useEffect } from 'react';
import '../../App.css';

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = 'The Ghost | Privacy Policy';
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='privacy-policy'>
      <h1>The Ghost Privacy Policy</h1>

      <div
        style={{ height: '25px' }} // spacing
      />

      <h2>Last Updated: February 26, 2025</h2>

      <div
        style={{ height: '25px' }} // spacing
      />

      <h3>Privacy Policy</h3>

      <div
        style={{ height: '10px' }} // spacing
      />
      <p>This privacy policy is applicable to the videogame known as "The Ghost" (hereinafter referred to as "<b>Game</b>"), which was developed by Gameplier (hereinafter referred to as "<b>Developer</b>"), and other online services (collectively, with the Game, the "<b>Services</b>").</p>

      <div
        style={{ height: '25px' }} // spacing
      />

      <h3>What information does the Game obtain and how is it used?</h3>

      <div
        style={{ height: '25px' }} // spacing
      />

      <h3>User Provided Information</h3>

      <div
        style={{ height: '10px' }} // spacing
      />

      <p>The Game acquires the information you supply when you download and register the Game. Registration with the Developer is not mandatory. However, bear in mind that you might not be able to utilize some of the features offered by the Game unless you register with them.</p>
      <p>The Developer may also use the information you provided them to contact you from time to time to provide you with important information, required notices and marketing promotions.</p>

      <div
        style={{ height: '25px' }} // spacing
      />

      <h3>Automatically Collected Information</h3>

      <div
        style={{ height: '10px' }} // spacing
      />

      <p>In addition, the Game may collect certain information automatically, including, but not limited to:</p>
      
      {/* , the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Game. */}
      <div // spacing
        style={{ height: '5px' }}
      />
        <li>Identifiers such as, nickname, name, IP address, email address, date of birth, country of residence, language, nationality etc.</li>
        <li>Device or Network Information such as, the type of mobile device you use, your mobile devices unique device ID, your mobile operating system, the type of mobile Internet browsers you use.</li>
        <li>Game information, such as information about crash report and system activity details(game setting, play date/time, etc.)</li>
        <li>Purchase information, such as purchase date, items, etc.</li>

      <div
        style={{ height: '25px' }} // spacing
      />

      <h3>Does the Game collect precise real time location information of the device?</h3>

      <div
        style={{ height: '10px' }} // spacing
      />

      <p>This Game collects your device's location, which helps the Developer determine your approximate geographical location and make use of in below ways:</p>
      <div // spacing
        style={{ height: '5px' }}
      />
        <li>Geolocation Services: The Developer utilizes location data to provide features such as personalized content, relevant recommendations, and location-based services.</li>
        <li>Analytics and Improvements: Aggregated and anonymized location data helps the Developer to analyze user behavior, identify trends, and improve the overall performance and functionality of the Game.</li>
        <li>Third-Party Services: Periodically, the Developer may transmit anonymized location data to external services. These services assist them in enhancing the Game and optimizing their offerings.</li>

      <div
        style={{ height: '25px' }} // spacing
      />

      <h3>Do third parties see and/or have access to information obtained by the Game?</h3>

      <div
        style={{ height: '10px' }} // spacing
      />
      <p>Only aggregated, anonymized data is periodically transmitted to external services to aid the Developer in improving the Game and their service. The Developer may share your information with third parties in the ways that are described in this privacy statement.</p>
      <p>Please note that the Game utilizes third-party services that have their own Privacy Policy about handling data. Below are the links to the Privacy Policy of the third-party service providers used by the Game:</p>
      
      <div // spacing
        style={{ height: '10px' }}
      />
        <li><a href="https://www.google.com/policies/privacy/" aria-label='Google Policy' target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>Google Play Services</a></li>
        <li><a href="https://support.google.com/admob/answer/6128543?hl=en" aria-label='AdMob Policy' target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>AdMob</a></li>
        <li><a href="https://unity3d.com/legal/privacy-policy" aria-label='Unity Policy' target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>Unity</a></li>
      <div // spacing
        style={{ height: '25px' }}
      />

      <p>The Developer may disclose User Provided and Automatically Collected Information:</p>
      <div // spacing
        style={{ height: '5px' }}
      />
        <li>as required by law, such as to comply with a subpoena, or similar legal process;</li>
        <li>when they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
        <li>with their trusted services providers who work on their behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>

      <div
        style={{ height: '25px' }} // spacing
      />
      <h3>What are my opt-out rights?</h3>
      <div
        style={{ height: '10px' }} // spacing
      />

      <p>You can halt all collection of information by the Game easily by uninstalling the Game. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile Game marketplace or network.</p>

      <div
        style={{ height: '25px' }} // spacing
      />
      <h3>Data Retention Policy, Managing Your Information</h3>
      <div
        style={{ height: '10px' }} // spacing
      />

      <p>The Developer will retain User Provided data for as long as you use the Game and for a reasonable time thereafter. The Developer will retain Automatically Collected information for up to 24 months and thereafter may store it in aggregate. If you'd like the Developer to delete User Provided Data that you have provided via the Game, please contact them at gameplier.team@gmail.com and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Game to function properly.</p>

      <div
        style={{ height: '25px' }} // spacing
      />
      <h3>Children</h3>
      <div
        style={{ height: '10px' }} // spacing
      />

      <p>The Developer does not use the Game to knowingly solicit data from or market to children under the age of 13.</p>

      <div
        style={{ height: '10px' }} // spacing
      />

      <p>The Game does not address anyone under the age of 13.
        The Developer does not knowingly collect personally
        identifiable information from children under 13 years of age. In the case
        the Developer discover that a child under 13 has provided
        personal information, the Developer will immediately
        delete this from their servers. If you are a parent or guardian
        and you are aware that your child has provided us with
        personal information, please contact the Developer (gameplier.team@gmail.com) so that
        they will be able to take the necessary actions.</p>

      <div
        style={{ height: '25px' }} // spacing
      />
      <h3>Security</h3>
      <div
        style={{ height: '10px' }} // spacing
      />

      <p>The Developer team are concerned about safeguarding the confidentiality of your information. The Developer provide physical, electronic, and procedural safeguards to protect information we process and maintain. For example, we limit access to this information to authorized employees and contractors who need to know that information in order to operate, develop or improve their Game. Please be aware that, although we endeavor provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.</p>

      <div
        style={{ height: '25px' }} // spacing
      />
      <h3>Changes</h3>
      <div
        style={{ height: '10px' }} // spacing
      />
      <p>This Privacy Policy may be updated from time to time for any reason. The Developer will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p>

      <div
        style={{ height: '25px' }} // spacing
      />
      <h3>Your Consent</h3>
      <div
        style={{ height: '10px' }} // spacing
      />

      <p>By using the Game, you are giving your consent to the Developer processing of your information as set forth in this Privacy Policy now and as amended by us. "Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information.</p>

      <div
        style={{ height: '25px' }} // spacing
      />
      <h3>Contact Us</h3>
      <div
        style={{ height: '10px' }} // spacing
      />
      <p>If you have any questions regarding privacy while using the Game, or have questions about the practices, please contact the Developer via email at gameplier.team@gmail.com.</p>

      <div
        style={{ height: '35px' }} // spacing
      />
    </div >
  );
}