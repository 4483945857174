import React, { useEffect } from 'react';

export default function AboutUs() {
  useEffect(() => {
    document.title = 'The Ghost | About Us';
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div style={{ paddingTop: '80px', color: 'white', backgroundColor: '#000000', textAlign: 'center' }}>
        <div>
          <picture>
            <source type="image/webp" srcset='/images/GameplierIcon.webp' />
            <source type="image/png" srcset='/images/GameplierIcon.png' />
            <img src='/images/GameplierIcon.png' alt='' width={220} height={220} />
          </picture>
        </div>
        <h2 style={{ marginTop: '-20px' }}>We make multiplayer games</h2>

        <p style={{ marginTop: '40px', marginBottom: '10px' }}>Since May 24, 2021</p>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ marginRight: '10px' }}>Oleh Sapovskyi</p>
          <a href='https://www.linkedin.com/in/oleh-sapovskyi' aria-label='LinkedIn Page' target="_blank" rel='noreferrer'>
            <img width={24} height={24} src='/icons/LinkedInBlue.png' alt='' style={{ marginTop: '3px' }} />
          </a>
        </div>

        <h3 style={{ marginTop: '40px' }}>Follow Us</h3>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '20px' }}>
          <a href='https://discord.com/invite/CDeyj4t58H' aria-label='Discord Invite' target="_blank" rel='noreferrer'>
            <img width={48} height={48} src='/icons/discord.svg' alt='' style={{ margin: '15px' }} />
          </a>

          {/* spacing */}
          <div style={{ width: '20px' }} />

          <a href='https://www.facebook.com/Gameplier' aria-label='Facebook Page' target="_blank" rel='noreferrer'>
            <img width={40} height={40} src='/icons/facebook.png' alt='' />
          </a>
        </div>

        <h3 style={{ marginTop: '20px' }}>Contact Us</h3>
        <a href='mailto:gameplier.team@gmail.com' aria-label='Mail to gameplier.team@gmail.com' style={{ textDecoration: 'underline', color: 'white' }}>
          <p style={{ marginTop: '20px', marginBottom: '35px' }}>gameplier.team@gmail.com</p>
        </a>
      </div>
    </div>
  );
}