import React, { useEffect } from 'react';
import '../../App.css';

export default function RulesOfConduct() {
  useEffect(() => {
    document.title = 'The Ghost | Rules of Conduct';
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='rules-of-conduct'>
      <h1>The Ghost Rules of Conduct</h1>

      {/* spacing */}
      <div style={{ height: '25px' }} />

      <p>The Ghost is a multiplayer horror survival game where players interact with each other via chat, voice chat or other means of communication</p>

      {/* spacing */}
      <div style={{ height: '25px' }} />

      <h2>Purpose</h2>

      {/* spacing */}
      <div style={{ height: '10px' }} />

      <p>This page outlines the standards of behavior expected from all players of The Ghost. It is designed to promote a positive, respectful, and inclusive environment. You will be held accountable for your actions regardless of whether or not you've read this.</p>

      {/* spacing */}
      <div style={{ height: '25px' }} />

      <h2>Unacceptable Behavior</h2>

      {/* spacing */}
      <div style={{ height: '10px' }} />

      <p>Unacceptable behaviors include, but are not limited to:</p>

      <li>Behaving inappropriately. Sexual suggestions, bullying, and other forms of misconduct are not accepted.</li>
      <li>Disrupt the flow of chat in chat rooms with vulgar language, abusiveness, hitting the return key repeatedly or inputting large images so the screen goes by too fast to read, use of excessive shouting [all caps] in an attempt to disturb other users, “spamming” or flooding.</li>
      <li>Impersonate another person, impersonating Gameplier staff.</li>
      <li>Inappropriate names, that are sexually explicit, obscene or that are unacceptable based on the social norms of each country.</li>
      <li>Attempt to get a password, account information, or other private information from anyone else on The Ghost Services.</li>
      <li>Promote or encourage any illegal activity including hacking, cracking or distribution of counterfeit software.</li>
      <li>Exploit errors in design, features which are not documented, and/or bugs to gain access that would otherwise not be available or to obtain any competitive advantage.</li>

      {/* spacing */}
      <div style={{ height: '25px' }} />

      <h2>Reporting Violations</h2>

      {/* spacing */}
      <div style={{ height: '10px' }} />

      <p>If you are being harassed, or if you witness or experience any behavior that violates this Rules of Conduct, please send report in game or at gameplier.team@gmail.com</p>

      {/* spacing */}
      <div style={{ height: '25px' }} />

      <h2>Safety & Privacy</h2>

      {/* spacing */}
      <div style={{ height: '10px' }} />

      <li>Choose strong passwords, keep your device secure, and report any suspicious activity.</li>
      <li>Beware of sharing any personal information like your real name, where you live, your birthday, phone numbers, emails, social media handles, passwords, etc. Do not follow suspicious links.</li>
      <li>Do not promote any dangerous or illegal activities, including but not limited to doxing, abuse, gambling, drug use, phishing, self-harm, etc. If you see this happening, report it immediately.</li>
      <li>If you see someone being harassed, report the offensive behavior in game.</li>

      {/* spacing */}
      <div style={{ height: '25px' }} />

      <h2>Consequences</h2>

      {/* spacing */}
      <div style={{ height: '10px' }} />

      <li><b>Griefing timeout:</b> If you receive a lot of griefing reports in game, you will receive a griefing timeout, timeout ranges from 1 hour to 2 weeks. Note that your in game trust factor will also decrease and you will get matched with low trusted players if you get frequent reports.</li>
      <li><b>Temporary ban:</b> You lose access to all in game services for a temporary amount of time (between 1 to 360 days)</li>
      <li><b>Permanent ban:</b> You lose access to all in game services permanently.</li>

      {/* spacing */}
      <div style={{ height: '35px' }} />
    </div>
  );
}