import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import UseClickOutside from '../hooks/ClickOutsideHook';
import './Navbar.css';

function Navbar() {
  const [show, setShow] = useState(true);
  const [click, setClick] = useState(false);

  const outsideClickWrapperRef = useRef("nav-menu-ref");
  UseClickOutside(outsideClickWrapperRef, () => {
    setClick(false);
  });

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const lastScrollRef = useRef();
  const handleScroll = () => {
    if (window.scrollY > 25 && window.scrollY > lastScrollRef.current) { // if scroll down hide the navbar
      setShow(false);
      setClick(false);
    } else { // if scroll up show the navbar
      setShow(true);
    }

    // remember current page location to use in the next move
    lastScrollRef.current = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <nav className={show ? 'navbar active' : 'navbar'}>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' aria-label='The Ghost Page' onClick={closeMobileMenu}>
            <img src='/theghostrounded.png' alt='' width={52} height={52} />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <img src={click ? '/icons/close_icon.svg' : '/icons/menu_icon.svg'} alt='' />
          </div>
        </div>
      </nav>
      <ul className={click ? 'nav-menu active' : 'nav-menu'} ref={outsideClickWrapperRef}>
        <li className='nav-item'>
          <Link
            to='/'
            className='nav-links'
            onClick={closeMobileMenu}
          >
            The Ghost
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            to='/about'
            className='nav-links'
            onClick={closeMobileMenu}
          >
            About Us
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            to='/terms_of_service'
            className='nav-links'
            onClick={closeMobileMenu}
          >
            Terms of Service
          </Link>
        </li>

        <li>
          <Link
            to='/privacy_policy'
            className='nav-links'
            onClick={closeMobileMenu}
          >
            Privacy Policy
          </Link>
        </li>

        <li>
          <Link
            to='/rules_of_conduct'
            className='nav-links'
            onClick={closeMobileMenu}
          >
            Rules of Conduct
          </Link>
        </li>
      </ul>
    </>
  );
}

export default Navbar;
