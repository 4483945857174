import React from 'react';
import { Link } from 'react-router-dom';
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>
          <div className='cards__item__pic-wrap'>
            <Zoom zoomImg={props.zoomSrc}>
              <picture>
                <source type="image/webp" srcset={props.webpSrc} />
                <source type="image/png" srcset={props.src} />
                <img
                  className='cards__item__img'
                  alt=''
                  src={props.src}
                />
              </picture>
            </Zoom>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;